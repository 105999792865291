<template>
  <div class="pl-md-3 pl-xs-1 d-flex flex-row align-center">
    <v-toolbar-title>
      {{ heading }}
    </v-toolbar-title>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-show="!enableAction && selectedItems.length < 1"
          class="ml-md-4 ml-sm-0"
          v-bind="attrs"
          v-on="on"
          text
          icon
          color="blue lighten-2 ml-3"
          @click="revisionReload"
        >
          <v-icon>{{ iconReload }}</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("tooltip.reload") }}</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-show="enableAction && meIsPeer"
          :disabled="selectedItems.length !== 1"
          class="ml-sm-0"
          v-bind="attrs"
          v-on="on"
          text
          icon
          color="purple lighten-2 ml-3"
          @click="menuAction('submit_as_peer')"
        >
          <v-icon>{{ iconSend }}</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("tooltip.submit-selected-revision") }}</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-show="enableAction"
          :disabled="selectedItems.length !== 1"
          class="ml-sm-0"
          v-bind="attrs"
          v-on="on"
          text
          icon
          color="purple lighten-2 ml-3"
          @click="menuAction('terminate')"
        >
          <v-icon>{{ iconDelete }}</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("tooltip.terminate-selected-revision") }}</span>
    </v-tooltip>

    <v-menu v-if="!userIsKrani && !meIsPeer && !userIsDirPjp" bottom left>
      <template v-slot:activator="{ on: menu, attrs }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              :data-hint="$t('introjs.this-button-for-submit-action')"
              data-hintposition="middle-middle"
              :disabled="selectedItems.length !== 1"
              v-show="enableAction"
              class="ml-sm-0"
              v-bind="attrs"
              v-on="{ ...tooltip, ...menu }"
              text
              icon
              color="purple lighten-2 ml-3"
            >
              <v-icon>{{ iconSend }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("tooltip.select-submit-to") }}</span>
        </v-tooltip>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, i) in menuSubmit"
          :key="i"
          @click="menuAction(item.command)"
        >
          <v-icon class="mr-3">{{ item.icon }}</v-icon>
          <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-menu
      v-if="$vuetify.breakpoint.smAndDown && !enableAction && selectedItems.length < 1"
      v-model="showSearch"
      :close-on-content-click="false"
      :nudge-width="300"
      offset-x
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          text
          icon
          color="blue lighten-2"
          class="d-flex d-sm-none"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>{{ iconSearch }}</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-col cols="12" sm="12" md="12">
          <v-text-field
            :label="$t('label.title-or-sender-name')"
            outlined
            v-model="searchQuery"
            v-on:keypress.enter="applyQuery"
            dense
            clearable
            hide-details="true"
          ></v-text-field>
        </v-col>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click="showSearch = false">{{
            $t("button.close")
          }}</v-btn>
          <v-btn color="primary" text @click="applyQuery">{{
            $t("button.search")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>

    <v-menu bottom left v-if="!enableAction && !$vuetify.breakpoint.smAndDown && selectedItems.length < 1">
      <template v-slot:activator="{ on: menu, attrs }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="{ ...tooltip, ...menu }"
              color="blue lighten-2 ml-3"
            >
              <v-icon>{{ iconFilter }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("tooltip.menu-filter") }}</span>
        </v-tooltip>
      </template>

      <v-list>
        <v-list-item
          v-for="(item, i) in menus"
          :key="i"
          class="cursor-pointer memo-header-menu"
          :class="{
            'v-list-item--active success white--text':
              compareSlug(filterParams.only, item.slug) === true,
          }"
          @click="menuAction(item.command, item.slug)"
        >
          <v-icon class="mr-3">{{ item.icon }}</v-icon>
          <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-tooltip bottom v-if="!$vuetify.breakpoint.smAndDown && !enableAction && selectedItems.length < 1">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-on="on"
          v-bind="attrs"
          v-model="searchQuery"
          v-on:keypress.enter="applyQuery"
          :label="$t('label.search')"
          :placeholder="$t('label.memo-title-or-draft-creator')"
          outlined
          dense
          clearable
          hide-details="true"
          class="ml-2 search-input"
          :class="{ 'min-w-300px': searchQuery }"
        ></v-text-field>
      </template>
      <span>{{ $t("tooltip.type-title-or-draft-creator-then-enter") }}</span>
    </v-tooltip>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import {
  mdiReload,
  mdiDotsVertical,
  mdiEmailSearchOutline,
  mdiFilterMenuOutline,
  mdiSend,
  mdiDeleteAlertOutline,
} from "@mdi/js";
import toolbarMenu from "@/constants/toolbarmenu";
export default {
  name: "header-left",
  components: {},
  props: {
    heading: {
      type: String,
      default: "",
    },
    enableAction: {
      type: Boolean,
    },
    selectedItems: {
      type: [Array, Object],
    },
  },
  data: () => ({
    iconReload: mdiReload,
    iconMenu: mdiDotsVertical,
    iconSearch: mdiEmailSearchOutline,
    iconFilter: mdiFilterMenuOutline,
    iconSend: mdiSend,
    iconDelete: mdiDeleteAlertOutline,
    menus: toolbarMenu.revisionFilterMenu,
    menuSubmit: toolbarMenu.createDraftToolbarMenu,
    showSearch: false,
  }),
  watch: {
    // searchQuery(val, oldVal) {
    //   if (!val && oldVal) {
    //     this.applyQuery();
    //   }
    // },
     searchQuery(val) {
      if (val && val.length < 3) return;
      let params = this.filterParams;
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        params.filter = val ? val : '';
        // this.loadDraft(params);
        this.applyQuery();
      }, 1000);
    }
  },
  computed: {
    ...mapState("draft", ["filterParams"]),
    ...mapState("user", ["currentUser"]),
    userIsDirPjp(){
      if (!this.currentUser) return false;
      return ["PJP","PJP_KANPUS","DIREKTUR"].includes(this.currentUser.level_aplikasi_elemen);
    },
    userIsKrani() {
      if (!this.currentUser) return false;
      return ["KRANI"].includes(this.currentUser.level_aplikasi_elemen);
    },
    searchQuery: {
      get() {
        return this.$store.state.draft.filterParams.filter;
      },
      set(val) {
        this.$store.commit("draft/SET_SEARCH_QUERY", val ? val : "");
      },
    },
    meIsPeer() {
      if (!this.selectedItems || this.selectedItems.length !== 1) return false;
      if (parseInt(this.selectedItems[0].revisionTypeId) === 1) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapActions(["setMessageBox"]),
    ...mapActions("draft", ["loadRevisions", "setFilterParams"]),
    revisionReload() {
      this.$emit("revisionReload");
    },
    applyQuery() {
      this.showSearch = false;
      // let filterParams = this.filterParams;
      // filterParams.filter = this.searchQuery ? this.searchQuery : "";
      // this.setFilterParams(filterParams);
      this.$emit("revisionReload");
      // this.loadRevisions(filterParams);
    },
    menuAction(command, slug) {
      if (command == "reset_filter") {
        this.searchQuery = "";
      } else {
        this.$emit("menuAction", { command: command, slug: slug });
      }
    },
    compareSlug(val1, val2) {
      if (!val1 || !val2) return;
      let res1 = val1.replace("&only=", "");
      // let res2 = val2.replace("filter_", "");
      return res1 === val2;
    },
  },
};
</script>