<template>
  <v-container fluid fill-height class="down-top-padding">
    <v-flex fill-height>
      <v-row>
        <v-col cols="12" sm="12" lg="12">
          <BaseCard :height="'100%'" class="memo-inbox">
            <header-left
              slot="left-toolbar"
              @revisionReload="revisionReload"
              @menuAction="menuAction"
              :selectedItems="selectedItems"
              :heading="cardHeading"
              :enableAction="selectedItems.length === 1"
            />
            <header-right
              slot="right-toolbar"
              @revisionReload="revisionReload"
              @menuAction="menuAction"
              :enableAction="selectedItems.length === 1"
            />

            <v-list
              three-line
              v-if="!networkError && !axiosLoading && myRevisionList"
            >
              <template v-for="(item, idx) in myRevisionList">
                <v-hover v-slot="{ hover }" :key="idx">
                  <v-list-item
                    :key="item.id_hash"
                    :class="{ 'on-hover': hover }"
                    class="v-list__revision"
                  >
                    <template v-slot:default="{ active }">
                      <label class="mr-2 check--blank"></label>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-list-item-avatar
                            v-on="on"
                            v-bind="attrs"
                            class="d-none d-sm-block mr-2"
                            :data-intro="
                              $t('introjs.animated-avatar-meaning-not-readed')
                            "
                            :class="{ blinking: item.status_aksi === 'unread' }"
                            @click="showRevisionDetail(item.id_hash)"
                          >
                            <v-icon v-bind:class="iconClass(item.status_aksi)"
                              >{{ iconStatus(item.status_aksi) }}
                            </v-icon>
                          </v-list-item-avatar>
                        </template>
                        <span>{{
                          `${$t("tooltip.revision-is-" + item.status_aksi)}`
                        }}</span>
                      </v-tooltip>
                      <v-list-item-content
                        v-on:click="showRevisionDetail(item.id_hash)"
                      >
                        <v-list-item-title>
                          <span
                            :data-intro="$t('introjs.this-is-sender-name')"
                            class="sub-headline"
                            :class="{
                              'unread--title': item.status_aksi === 'unread' || parseInt(item.is_bold) === 1 ,
                            }"
                            @click="showRevisionDetail(item.id_hash)"
                          >
                            {{ cutJudul(item.hal, 150) }}
                          </span>
                          <div
                            class="d-inline-flex align-self-center align-center align--vertical-center"
                            :data-intro="
                              $t('introjs.badge-is-tag-for-memo-status')
                            "
                          >
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  v-if="parseInt(item.ada_lampiran) == 1"
                                  class="att-icon mx-1"
                                  color="primary"
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ iconAttachment }}
                                </v-icon>
                              </template>
                              <span>{{ $t("tooltip.attachment-exist") }}</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  v-if="parseInt(item.approver_valid) !== 1"
                                  class="att-icon mx-1"
                                  color="error"
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ iconWarning }}
                                </v-icon>
                              </template>
                              <span>{{
                                $t("tooltip.approver-is-changed")
                              }}</span>
                            </v-tooltip>

                            <div v-if="!$vuetify.breakpoint.smAndDown">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    v-if="item.tag.includes('rahasia')"
                                    class="att-icon mx-1"
                                    color="warning"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    {{ iconSecret }}
                                  </v-icon>
                                </template>
                                <span>{{ $t("tooltip.secret") }}</span>
                              </v-tooltip>
                            </div>
                            <v-tooltip
                              bottom
                              v-if="$vuetify.breakpoint.smAndDown"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <a
                                  v-bind="attrs"
                                  v-on="on"
                                  v-if="item.tag.includes('rahasia')"
                                  href="javascript:void(0)"
                                  class="label theme-bg3 text-white f-12"
                                  >{{ $t("badge.confidential") }}</a
                                >
                              </template>
                              <span>{{ $t("tooltip.confidential") }}</span>
                            </v-tooltip>

                            <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-if="item.referensi != null" class="att-icon mx-1" color="warning" dark
                                v-bind="attrs" v-on="on">
                                {{ iconReply }}
                              </v-icon>
                          </template>
                          <span>{{ $t("tooltip.replied-memo") }}</span>
                        </v-tooltip>

                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <a
                                  v-bind="attrs"
                                  v-on="on"
                                  v-if="item.tipe === 'bom'"
                                  href="javascript:void(0)"
                                  class="label theme-bg4 text-white f-12"
                                  >{{ $t("badge.directors-memo") }}</a
                                >
                              </template>
                              <span>{{ $t("tooltip.directors-memo") }}</span>
                            </v-tooltip>

                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <a
                                  v-bind="attrs"
                                  v-on="on"
                                  v-if="item.tipe === 'masuk'"
                                  href="javascript:void(0)"
                                  class="label theme-bg6 text-white f-12"
                                  >{{ $t("badge.incoming-letter") }}</a
                                >
                              </template>
                              <span>{{ $t("tooltip.incoming-letter") }}</span>
                            </v-tooltip>

                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <a
                                  v-bind="attrs"
                                  v-on="on"
                                  v-if="item.tipe === 'izin_prinsip'"
                                  href="javascript:void(0)"
                                  class="label theme-bg4 text-white f-12"
                                  >{{ $t("badge.permit-memo") }}</a
                                >
                              </template>
                              <span>{{ $t("tooltip.permit-memo") }}</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <a
                                  v-bind="attrs"
                                  v-on="on"
                                  v-if="item.tipe === 'subholding'"
                                  href="javascript:void(0)"
                                  class="label theme-bg7 text-white f-12"
                                  >{{ $t("badge.subholding-memo") }}</a
                                >
                              </template>
                              <span>{{ $t("tooltip.subholding-memo") }}</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <a
                                  v-bind="attrs"
                                  v-on="on"
                                  v-if="item.tipe === 'sppd'"
                                  href="javascript:void(0)"
                                  class="label theme-bg2 text-white f-12"
                                  >{{ $t("badge.sppd") }}</a
                                >
                              </template>
                              <span>{{ $t("tooltip.subholding-memo") }}</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <a
                                  v-bind="attrs"
                                  v-on="on"
                                  v-if="item.tipe === 'xnusantara'"
                                  href="javascript:void(0)"
                                  class="label theme-bg8 text-white f-12"
                                  >{{ $t("badge.xnusantara-letter") }}</a
                                >
                              </template>
                              <span>{{ $t("tooltip.xnusantara-letter") }}</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <a
                                  v-bind="attrs"
                                  v-on="on"
                                  v-if="item.tipe === 'eksternal'"
                                  href="javascript:void(0)"
                                  class="label theme-bg3 text-white f-12"
                                  >{{ $t("badge.external-letter") }}</a
                                >
                              </template>
                              <span>{{ $t("tooltip.external-letter") }}</span>
                            </v-tooltip>

                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <a
                                  v-bind="attrs"
                                  v-on="on"
                                  v-if="isUrgent(item.tag)"
                                  href="javascript:void(0)"
                                  class="label theme-bg3 text-white f-12"
                                  >{{ $t("badge.urgent") }}</a
                                >
                              </template>
                              <span>{{ $t("tooltip.permit-memo") }}</span>
                            </v-tooltip>
                          </div>
                        </v-list-item-title>

                        <v-list-item-subtitle
                          :data-intro="$t('introjs.this-is-memo-latest-status')"
                          @click="showRevisionDetail(item.id_hash)"
                          class="revison__list-subtitle"
                          :class="{
                            'unread--title primary--text':
                              item.status_aksi === 'unread' || parseInt(item.is_bold) === 1,
                            'error--text': item.status_aksi === 'locked',
                            'warning--text': item.status_aksi === 'pending',
                            'success--text': item.status_aksi === 'done',
                          }"
                        >
                          {{
                            `${$t("label.draft-status")}: ${cutJudul(
                              item.rincian_status,
                              150
                            )}`
                          }}
                        </v-list-item-subtitle>

                        <v-list-item-subtitle
                          :data-intro="
                            $t('introjs.this-is-memo-number-and-title')
                          "
                          class="d-none d-sm-block revison__list-subtitle"
                          @click="showRevisionDetail(item.id_hash)"
                        >
                          {{
                            `${$t("label.draft-submitter")}: ${cutJudul(
                              item.yang_menyerahkan,
                              150
                            )}`
                          }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle
                          class="d-block d-sm-none revison__list-subtitle"
                          :class="{ 'unread--title': !item.readed_at }"
                          @click="showRevisionDetail(item.id_hash)"
                        >
                          {{
                            `${$t("label.draft-submitter")}: ${cutJudul(
                              item.yang_menyerahkan,
                              150
                            )}`
                          }}
                        </v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action
                        v-on:click.self="showRevisionDetail(item.id_hash)"
                      >
                        <v-tooltip left>
                          <template v-slot:activator="{ on, attrs }">
                            <span
                              v-on="on"
                              v-bind="attrs"
                              :data-intro="$t('introjs.this-is-sending-date')"
                              @click="showRevisionDetail(item.id_hash)"
                            >
                              {{ $date(item.data_time).format("DD MMM YYYY") }}
                              <br />
                              {{ $date(item.data_time).format("HH:mm") }} WIB
                            </span>
                          </template>
                          <span>{{ item.data_time | diffForHumans }}</span>
                        </v-tooltip>

                        <div
                          class="button__list-container"
                          v-show="hover"
                          v-bind:class="{ 'on-hover': active }"
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-on="on"
                                v-bind="attrs"
                                class="mx-2"
                                fab
                                :small="$vuetify.breakpoint.smAndDown"
                                dark
                                color="primary"
                                v-on:click="
                                  showRevisionDetail(item.id_hash, true)
                                "
                              >
                                <v-icon dark> {{ iconZoom }} </v-icon>
                              </v-btn>
                            </template>
                            <span>{{
                              $t("tooltip.open-revision-detail")
                            }}</span>
                          </v-tooltip>

                          <v-tooltip bottom v-if="item.approver_flag == true">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                class="mx-2"
                                fab
                                dark
                                :small="$vuetify.breakpoint.smAndDown"
                                color="primary"
                                v-on="on"
                                v-bind="attrs"
                                v-if="
                                  unreadOrPending({
                                    hasRead: item.readed_at,
                                    memoStatus: item.memo_status_id,
                                    revisionStatus: item.revision_status_id,
                                    revisionTypeId: item.revision_type_id,
                                  }) &&
                                  enableSubmitToUpline(item.is_bom) &&
                                  !revisionIsUnread(item.readed_at) &&
                                  parseInt(item.revision_type_id) === 2
                                "
                                v-on:click="
                                  submitSelected({
                                    revisionId: item.id_hash,
                                    hasRead: item.readed_at,
                                    memoStatus: item.memo_status_id,
                                    revisionStatus: item.revision_status_id,
                                    revisionTypeId: item.revision_type_id,
                                  })
                                "
                              >
                                <v-icon dark> {{ iconSubmitUpline }} </v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $t("tooltip.submit-to-upline") }}</span>
                          </v-tooltip>

                          <v-tooltip bottom v-if="item.approver_flag">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-on="on"
                                v-bind="attrs"
                                class="mx-2"
                                :small="$vuetify.breakpoint.smAndDown"
                                fab
                                dark
                                color="primary"
                                v-if="
                                  unreadOrPending({
                                    hasRead: item.readed_at,
                                    memoStatus: item.memo_status_id,
                                    revisionStatus: item.revision_status_id,
                                    revisionTypeId: item.revision_type_id,
                                  }) &&
                                  !['DIREKTUR', 'PJP', 'PJP_KANPUS'].includes(
                                    currentUserType
                                  ) &&
                                  !revisionIsUnread(item.readed_at) &&
                                  canShare(item.memo_status_id) &&
                                  parseInt(item.revision_type_id) === 2
                                "
                                v-on:click="shareSelected(item.id_hash)"
                              >
                                <v-icon dark> {{ iconShareToPeers }} </v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $t("tooltip.share-to-revision") }}</span>
                          </v-tooltip>

                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-on="on"
                                v-bind="attrs"
                                class="mx-2"
                                fab
                                :small="$vuetify.breakpoint.smAndDown"
                                dark
                                color="primary"
                                v-if="
                                  unreadOrPending({
                                    hasRead: item.readed_at,
                                    memoStatus: item.memo_status_id,
                                    revisionStatus: item.revision_status_id,
                                    revisionTypeId: item.revision_type_id,
                                  }) &&
                                  !['DIREKTUR', 'PJP', 'PJP_KANPUS'].includes(
                                    currentUserType
                                  ) &&
                                  !revisionIsUnread(item.readed_at) &&
                                  parseInt(item.revision_type_id) === 1
                                "
                                v-on:click="submitAsPeer(item.id_hash)"
                              >
                                <v-icon dark> {{ iconSend }} </v-icon>
                              </v-btn>
                            </template>
                            <span>{{
                              $t("tooltip.submit-this-revision")
                            }}</span>
                          </v-tooltip>

                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-on="on"
                                v-bind="attrs"
                                class="mx-2"
                                fab
                                :small="$vuetify.breakpoint.smAndDown"
                                dark
                                color="red"
                                v-on:click="terminateSelected(item.id_hash)"
                                v-if="
                                  unreadOrPending({
                                    hasRead: item.readed_at,
                                    memoStatus: item.memo_status_id,
                                    revisionStatus: item.revision_status_id,
                                    revisionTypeId: item.revision_type_id,
                                  })
                                "
                              >
                                <v-icon dark> {{ iconDelete }} </v-icon>
                              </v-btn>
                            </template>
                            <span>{{
                              $t("tooltip.terminate-this-draft")
                            }}</span>
                          </v-tooltip>
                        </div>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                </v-hover>
              </template>
            </v-list>
            <v-card-text v-if="networkError || axiosLoading || !myRevisionList">
              <v-skeleton-loader
                class="mx-auto"
                max-width="100%"
                type="list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line"
              ></v-skeleton-loader>
            </v-card-text>
          </BaseCard>
        </v-col>
      </v-row>
    </v-flex>
    <peer-selector
      v-bind:showPeerDialog="showPeerDialog"
      v-on:showHidePeerDialog="showHidePeerDialog"
      v-on:peerSelected="peerSelected"
    ></peer-selector>

    <insert-comment
      v-bind:showCommentDialog="showCommentDialog"
      v-bind:submitOptions="submitOptions"
      v-on:showHideCommentDialog="showHideCommentDialog"
      v-on:commentFilled="commentFilled"
    ></insert-comment>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import headerLeft from "./HeaderLeft";
import HeaderRight from "./HeaderRight";
import peerSelector from "@/components/commonComponents/PeerSelector.vue";
import insertComment from "@/components/commonComponents/InsertComment.vue";
import { isIntroActive } from "@/constants/config";
import dayjs from "dayjs";
import "intro.js/minified/introjs.min.css";

import {
  mdiAttachment,
  mdiStarCircle,
  mdiIncognito,
  mdiBriefcaseCheck,
  mdiClockFast,
  mdiSpeedometerSlow,
  mdiSpeedometerMedium,
  mdiSpeedometer,
  mdiCogTransfer,
  mdiEmailEditOutline,
  mdiSendCheck,
  mdiCheckboxBlankOutline,
  mdiEyeOff,
  mdiLockClock,
  mdiDelete,
  mdiElevatorUp,
  mdiAccountGroup,
  mdiClose,
  mdiMagnifyPlusOutline,
  mdiSend,
  mdiAlertOctagram,
  mdiReply,
} from "@mdi/js";

export default {
  components: {
    headerLeft,
    HeaderRight,
    peerSelector,
    insertComment,
  },
  data: () => ({
    iconAttachment: mdiAttachment,
    iconWarning: mdiAlertOctagram,
    iconUrgent: mdiClockFast,
    iconBom: mdiStarCircle,
    iconSecret: mdiIncognito,
    iconPermit: mdiBriefcaseCheck,
    iconSlow: mdiSpeedometerSlow,
    iconMedium: mdiSpeedometerMedium,
    iconFast: mdiSpeedometer,
    iconDirect: mdiCogTransfer,
    iconEdit: mdiEmailEditOutline,
    iconSent: mdiSendCheck,
    iconSend: mdiSend,
    iconCheckBlank: mdiCheckboxBlankOutline,
    iconUnread: mdiEyeOff,
    iconLock: mdiLockClock,
    iconDelete: mdiDelete,
    iconSubmitUpline: mdiElevatorUp,
    iconShareToPeers: mdiAccountGroup,
    iconClose: mdiClose,
    iconZoom: mdiMagnifyPlusOutline,
    selectedItems: [],
    selectedItemId: null,
    isCheckedAll: false,
    timeout: null,
    selectedPeers: [],
    comment: null,
    showPeerDialog: false,
    showCommentDialog: false,
    submitOptions: undefined,
    iconReply:mdiReply,
  }),
  filters: {
    diffForHumans: (date) => {
      if (!date) {
        return "";
      }
      return dayjs(date).fromNow();
    },
  },
  computed: {
    ...mapState(["isMobile", "networkError"]),
    ...mapState("user", ["currentUser"]),
    ...mapState("draft", [
      "revisionList",
      "axiosLoading",
      "filterParams",
      "selectAllRevision",
    ]),
    myRevisionList() {
      if (!this.revisionList) return [];
      return this.revisionList.data;
    },
    currentUserType() {
      if (!this.currentUser) return null;
      return this.currentUser.level_aplikasi_elemen;
    },
    cardHeading() {
      let result = null;
      if (this.$route.name === "revision") {
        result = this.$t("card.revision-title");
      } else if (this.$route.name === "approval") {
        result = this.$t("card.approval-title");
      }
      return result;
    },
  },
  created() {
    this.revisionReload();
  },
  mounted() {
    if (isIntroActive === true) {
      const introJS = require("intro.js");
      setTimeout(() => {
        introJS
          .introJs()
          .setOptions({
            exitOnOverlayClick: false,
          })
          .start();
      }, 2000);
    }
  },

  methods: {
    ...mapActions("draft", [
      "loadRevisions",
      "emptyRevision",
      "setFilterParams",
      "setAxiosLoading",
      "terminateThisDraft",
      "setSingleRevision",
      "submitDraftFromList",
      "shareMyRevision",
      "submitRevisionAsPeerList",
    ]),
    showRevisionDetail(id, button) {
      //beri jeda untuk animasi ripple
      if (!this.$vuetify.breakpoint.smAndDown) {
        return setTimeout(() => {
          this.$router.push({
            name: "revision.detail",
            params: { id: id },
          });
        }, 300);
      }
      if (this.$vuetify.breakpoint.smAndDown && button) {
        return setTimeout(() => {
          this.$router.push({
            name: "revision.detail",
            params: { id: id },
          });
        }, 300);
      }
    },
    isUrgent(tag) {
      return tag.includes("urgent");
    },
    isSecret(tag) {
      return tag.includes("secret");
    },
    showRevisionDetailMobile() {
      this.$router.push({
        name: "revision.detail",
        params: { id: this.selectedItemId },
      });
    },
    revisionReload() {
      //remove dulu selected item
      this.selectedItems = [];
      this.emptyRevision();
      this.loadRevisions(this.filterParams);
    },
    filterData(val) {
      if (!val) return;
      let filterOnly = "&only=" + val.replace("filter_", "");
      let filterParams = this.filterParams;
      filterParams.only = filterOnly;
      this.setFilterParams(filterParams);

      this.loadRevisions(this.filterParams);
    },
    menuAction(params) {
      switch (params.command) {
        case "submit":
          this.submitSelected();
          break;
        case "share_to_revision":
          this.shareSelected();
          break;
        case "terminate":
          this.terminateSelected();
          break;
        case "submit_as_peer":
          this.submitSelected(true);
          break;
        case "filter_data":
          this.filterData(params.slug);
          break;
        default:
          break;
      }
    },
    terminateSelected(id) {
      this.selectedItemId = id;
      if (!this.comment) {
        this.submitOptions = {
          mode: "termination",
          btnText: "button.terminate",
          btnIcon: this.iconDelete,
          commentDesc: this.$t(
            "text.please-fill-termination-comment-or-reason"
          ),
        };
        return (this.showCommentDialog = true);
      }

      if (this.submitOptions.mode !== "termination") return;

      let formData = {
        params: this.selectedItemId,
        comment: this.comment,
      };

      this.terminateThisDraft({
        revisionId: this.selectedItemId,
        formData: formData,
      });

      this.$nextTick(() => {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.submitOptions = undefined;
          this.comment = undefined;
          this.selectedItemId = undefined;
        }, 300);
      });
    },
    submitAsPeer(revisionId) {
      this.$dialog
        .confirm({
          text: this.$t("msgbox.are-you-sure-to-submit-this-draft-as-peer"),
          title: this.$t("msgbox.submit-revision"),
          persistent: true,
          icon: this.iconQuestion,
          color: "danger",
          actions: [
            { text: this.$t("button.cancel"), color: "red", key: false },
            {
              text: this.$t("button.yes-submit"),
              color: "success",
              key: true,
            },
          ],
        })
        .then((result) => {
          if (result === true) {
            let formData = {
              id_hash: revisionId,
            };
            this.submitRevisionAsPeerList({ formData: formData });
            // this.submitRevisionFromList({ formData: formData });
            this.$nextTick(() => {
              if (this.timeout) clearTimeout(this.timeout);
              this.timeout = setTimeout(() => {
                this.selectedItemId = undefined;
              }, 300);
            });
          }
        });
    },
    submitSelected(params) {
      //  params = {hasRead:,
      if (params.hasRead == null) {
        return this.$dialog.error({
          text: this.$t("msgbox.cannot-submit-unread-draft"),
          title: this.$t("msgbox.submit-revision"),
          persistent: true,
        });
      } else {
        this.$dialog
          .confirm({
            text: this.$t("msgbox.are-you-sure-to-submit-this-draft-to-upline"),
            title: this.$t("msgbox.submit-revision"),
            persistent: true,
            icon: this.iconQuestion,
            color: "danger",
            actions: [
              { text: this.$t("button.cancel"), color: "red", key: false },
              {
                text: this.$t("button.yes-submit"),
                color: "success",
                key: true,
              },
            ],
          })
          .then((result) => {
            if (result === true) {
              let formData = {
                id_hash: params.revisionId,
              };
              this.submitDraftFromList({ formData: formData });
              // this.submitRevisionFromList({ formData: formData });
              this.$nextTick(() => {
                if (this.timeout) clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                  this.selectedItems = [];
                  this.selectedItemId = undefined;
                }, 300);
              });
            }
          });
      }
    },
    shareSelected(revisionId) {
      if (!revisionId) return;
      this.selectedItemId = revisionId;
      if (!this.selectedPeers || this.selectedPeers.length < 1) {
        return (this.showPeerDialog = true);
      }

      let formData = {
        reviser_nik: this.selectedPeers,
      };
      this.shareMyRevision({ memoId: revisionId, formData: formData });
      // this.submitRevisionFromList({ formData: formData });
      this.$nextTick(() => {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.selectedItemId = undefined;
          this.selectedPeers = undefined;
        }, 300);
      });
    },

    cutJudul(str, strLen) {
      if (this._deviceType() == "smartphone") {
        return this.strCutStripped(str, strLen);
      } else {
        return this.strCutStripped(str, strLen);
      }
    },
    cutIsi(str, strLen) {
      if (this._deviceType() == "smartphone") {
        return this.strCutStripped(str, strLen);
      } else {
        return this.strCutStripped(str, strLen);
      }
    },
    getStatus(params) {
      if (!params) return "locked";
      return this.draftStatus({
        hasRead: params.hasRead,
        memoStatus: parseInt(params.memoStatus),
        revisionStatus: parseInt(params.revisionStatus),
        revisionTypeId: parseInt(params.revisionTypeId),
        currentUserType: this.currentUserType,
      });
    },
    unreadOrPending(params) {
      if (!params) return false;
      if (
        ["unread", "pending"].includes(
          this.draftStatus({
            hasRead: params.hasRead,
            memoStatus: parseInt(params.memoStatus),
            revisionStatus: parseInt(params.revisionStatus),
            revisionTypeId: parseInt(params.revisionTypeId),
            currentUserType: this.currentUserType,
          })
        )
      )
        return true;
      return false;
    },
    revisionIsUnread(val) {
      if (!val || val == "null") return true;
      return false;
    },
    showHidePeerDialog(val) {
      this.showPeerDialog = val;
    },
    showHideCommentDialog(val) {
      this.showCommentDialog = val;
    },
    peerSelected(val) {
      if (!val) return;
      this.selectedPeers = val.map(({ nik }) => nik).join();
      this.shareSelected(this.selectedItemId);
    },
    commentFilled(val) {
      if (!val) return;
      this.comment = val;
      if (this.submitOptions.mode === "termination")
        this.terminateSelected(this.selectedItemId);
    },
    enableSubmitToUpline(isBom) {
      let isPjpDir = ["PJP", "PJP_KANPUS", "DIREKTUR"].includes(
        this.currentUserType
      );
      let isPjp = ["PJP", "PJP_KANPUS"].includes(this.currentUserType);

      if (!isPjpDir) return true;
      if (isPjp && parseInt(isBom) === 1) return true;
      return false;
    },
    canShare(memoStatusId) {
      if (!memoStatusId) return false;
      return ![25, 35].includes(parseInt(memoStatusId));
    },
    iconStatus(params) {
      if (!params) return this.iconLock;
      let result = this.iconLock;
      switch (params) {
        case "locked":
          result = this.iconLock;
          break;
        case "unread":
          result = this.iconUnread;
          break;
        case "pending":
          result = this.iconEdit;
          break;
        case "done":
          result = this.iconSent;
          break;
        default:
          break;
      }
      return result;
    },
    iconClass(params) {
      if (!params) return "gray--text";
      let result = "primary--text";
      switch (params) {
        case "locked":
          result = "red--text";
          break;
        case "pending":
          result = "warning--text";
          break;
        case "done":
          result = "success--text";
          break;
        default:
          break;
      }
      return result;
    },
    // revisionStatus(status, readedAt){
    //   if (!status && (!readedAt || readedAt =='null')) return 'revision-is-unread';
    //   if (!status && readedAt) return 'revision-is-pending';
    // }
  },
};
</script>
